(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('SalesOrderDetailController', SalesOrderDetailController);

    SalesOrderDetailController.$inject = ['$scope', '$q', '$state','$rootScope', '$stateParams', '$uibModalInstance', 'entity', 'SalesOrder',
    'Qinius', 'History', 'MessageService', 'Vert','ProductXmls','$uibModal', 'PlatformSetting','OrderArticle','Product','$localStorage','$timeout'];

    function SalesOrderDetailController($scope, $q, $state,$rootScope, $stateParams, $uibModalInstance, entity, SalesOrder,
     Qinius, History, MessageService, Vert, ProductXmls,$uibModal, PlatformSetting, OrderArticle, Product,$localStorage, $timeout) {
        var vm = this;

        vm.salesOrder = entity.data;
        vm.clear = clear;

        vm.orderId = $stateParams.id;

        vm.domains = Qinius.getDomain();
        $q.all([vm.domains.$promise]).then(function(){
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
        });

        if (vm.orderId !=null ) {
            History.getAllByCondition(
            {items:[{key:"classId", op:"=", value: vm.orderId},{key:"className", op:'=', value:"SalesOrder"}],sort:["createdDate,desc"]}, function(data){
                if (data && data.status == 200) {
                    vm.historysCB = data.data;
                    paging();
                }
            });
        }

        $scope.downFile = function(line){
            if (!line.filePath) {
                MessageService.error("作品文件不存在，下载失败");
                return;
            }
            window.open(line.filePath);
        }

        $scope.getLineStatus = function(line){
            if (line == null || !line.status) {
                return;
            }
           switch (line.status) {
                case "New":
                    return "待组版";
                case "Combinationing":
                    return "组版中";
                case "Combinationed":
                    return "组版完成";
                case "CombinationError":
                    return "组版失败";
                case "Completed":
                    return "组版完成";
                case "Cancelled":
                    return "订单行取消";
                default:
                    return "";
            }
        }
        function paging() {
            var nums = 15; //每页出现的数量
            var pages = Math.ceil(vm.historysCB.length/nums); //得到总页数
            var thisDate = function(curr){
                //此处只是演示，实际场景通常是返回已经当前页已经分组好的数据
                var str = [], last = curr*nums - 1;
                last = last >= vm.historysCB.length ? (vm.historysCB.length-1) : last;
                for(var i = (curr*nums - nums); i <= last; i++){
                    str.push(vm.historysCB[i]);
                }
                return str;
            };

            //调用分页
            laypage({
                cont: 'biuuu_city',
                curr: vm.page, //初始化当前页
                pages: pages,
                skip: true, //是否开启跳页
                first: false,
                last: false,
                jump: function(obj){
                    $timeout(function () {
                        vm.historys=thisDate(obj.curr);
                        vm.page = obj.curr;
                    })
                }
            });
        }


        $scope.getLength = function(des){
            if (des.length > 20) {
                return des.substring(0,19)+"...";
            }else{
                return des;
            }
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        var unsubscribe = $rootScope.$on('backApp:salesOrderUpdate', function(event, result) {
            vm.salesOrder = result;
        });

        $scope.editorArticle = function(line){
            PlatformSetting.get({}, function (res) {
                if(!res.data.editorNotifyUrl){
                    MessageService.error("请设置editorNotifyUrl");
                    return;
                }
                OrderArticle.getAuth({id: line.orderArticle.id}, function (res1) {
                    if(res1.status == 200){
                        if(line.orderArticle.editorType == "PICTURE" || line.orderArticle.editorType == "PICTURE_PRO") {
                            window.open(res.data.editorNotifyUrl + '/#/' + vm.salesOrder.storeId + '/' + line.orderArticle.id + '/printOrder?authorization=' + res1.data)
                        }else{
                            window.open(res.data.editorNotifyUrl + '/#/' + vm.salesOrder.storeId + '/' + line.orderArticle.id + '/photobookOrder?authorization=' + res1.data)
                        }
                    }else{
                        MessageService.error(res1.message || "获取token失败，请稍后再试！");
                    }
                })
            })
        };
        $scope.goToView = function (orderArticle) {
            if(!orderArticle.articleId){
                MessageService.error("没有作品");
                return;
            }
            PlatformSetting.get({}, function (res) {
                if(!res.data.editorNotifyUrl){
                    MessageService.error("请设置editorNotifyUrl");
                    return;
                }
                console.log(res.data.editorNotifyUrl + '/#/' + vm.salesOrder.storeId + '/' + orderArticle.id + '/articlePreview2')
                window.open(res.data.editorNotifyUrl + '/#/' + vm.salesOrder.storeId + '/' + orderArticle.id + '/articlePreview2')
            })
        };

        $scope.$on('$destroy', unsubscribe);
    }
})();
