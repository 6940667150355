(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('FontManagementDialogController', FontManagementDialogController);

    FontManagementDialogController.$inject = ['$timeout', '$scope', 'UidService', '$stateParams', '$uibModalInstance', 'entity', 'FontManagement', 'Upload', 'MessageService', 'Qinius'];

    function FontManagementDialogController ($timeout, $scope, UidService, $stateParams, $uibModalInstance, entity, FontManagement, Upload, MessageService, Qinius) {
        var vm = this;

        vm.fontManagement = entity;
        vm.clear = clear;
        vm.save = save;

        vm.types =[{type:"wechat",name:'全部可用'},{type:"all",name:'模板可用'}];
        vm.fontManagement.type = vm.types[0].type;

        Qinius.get(function (msg) {
            vm.token = msg.token;
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }


        vm.files = [];
        window.uploadFile = function ($event) {
            vm.files = $event.target.files;
            if (vm.files.length > 0) {
                qiniuUpload(0);
            }
        }
        function qiniuUpload(index) {
            var file = vm.files[index];
            var formData = new FormData();
            formData.append('file', file);
            formData.append('unique_names', true);
            formData.append('key', "font-" + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: false,
                contentType: false,
                processData: false,
                success: function(data) {
                    Upload.upload({
                        type: 'POST',
                        url: 'manager/api/font-managements/upload', //上传路径
                        data: {
                            file: file,
                            identifier: data.key,
                            name: file.name,
                            type: vm.fontManagement.type
                        }
                    }).then(function successCallback(res) {
                        if (res.status && res.status == 200) {
                            if(index < vm.files.length-1){
                                qiniuUpload(index+1);
                            }else{
                                MessageService.success("字体上传成功");
                                $uibModalInstance.close(res);
                            }
                        }else{
                            MessageService.error(file.name + "字体上传失败");
                            if(index < vm.files.length-1){
                                qiniuUpload(index+1);
                            }
                        }
                    }, function errorCallback(response) {
                        if(index < vm.files.length-1){
                            qiniuUpload(index+1);
                        }
                        MessageService.error(file.name + "字体上传失败");
                    });
                },
                error: function (request) {
                    if(index < vm.files.length-1){
                        qiniuUpload(index+1);
                    }
                    MessageService.error(file.name + "文件上传失败");
                }
            });
        };


        function save () {
            vm.isSaving = true;
            if (vm.fontManagement.id !== null) {
                FontManagement.update(vm.fontManagement, onSaveSuccess, onSaveError);
            } else {
                FontManagement.save(vm.fontManagement, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('editorApp:fontManagementUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
